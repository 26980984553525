import React from 'react';
// import { Link } from 'react-router-dom';

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from "swiper";
import { Pagination as SwiperPag } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

// import Link from '@mui/material/Link';

const ShopSwiper = ({ images, backup_banner }) => {
    // const navigate = useNavigate();
    return (
        <Swiper
            navigation={true}
            pagination={{
                clickable: true
            }}
            autoplay={{
                delay: 8000,
                disableOnInteraction: false
            }}
            loop={
                images.length > 1 ? true : false
            }
            modules={[Navigation, SwiperPag, Autoplay]}
            style={{ width: '100%', height: '100%', zIndex: 0 }}
        >
            {
                images.length > 0 ?
                    images.map(({ imgId, url }) => (
                        <SwiperSlide key={imgId}>
                            {/* <Link component="button"
                                underline="none"
                                onClick={ () => navigate(url) }> */}
                                {/* <Link to={ url }> */}
                                <a href={ url }>
                                    <img
                                        src={`${GOOGLE_DRIVE_CDN}${imgId}`}
                                        width="100%"
                                        style={{ objectFit: 'cover' }}
                                        // pointerEvents: 'none'
                                        alt="banner-nefropolis"
                                    />
                                </a>
                                {/* </Link> */}
                            {/* </Link> */}
                        </SwiperSlide>
                    ))
                :
                    backup_banner.map(id => (
                        <SwiperSlide key={id}>
                            <img src={`${GOOGLE_DRIVE_CDN}${id}`}
                                width="100%"
                                style={{ objectFit: 'cover', pointerEvents: 'none' }}
                                alt="banner-nefropolis"
                            />
                        </SwiperSlide>
                    ))
            }
        </Swiper>
    );
};

export default ShopSwiper;