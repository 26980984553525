import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const PublicRoute = ({ children }) => {
    const { loggedUser } = useSelector(state => state.user);
    const { email, privileges, page } = loggedUser;
    switch(true) {
        case (page === 'index'):
            return <Navigate to="/" />;
        case (email !== '' && privileges && privileges.main):
            switch(page) {
                case 'mi-cuenta':
                    return <Navigate to="/nefro-admin" />;
                case 'checkout':
                    return <Navigate to="/checkout" />;
                case '':
                    return <Navigate to="/nefro-admin" />;
                default:
                    return <Navigate to={ `/${page}` } />;
            }
        case (email !== '' && privileges === undefined):
            switch(page) {
                case 'mi-cuenta':
                    return <Navigate to="/mi-cuenta" />;
                case 'checkout':
                    return <Navigate to="/checkout" />;
                case '':
                    return <Navigate to="/mi-cuenta" />;
                default:
                    return <Navigate to={ `/${page}` } />;
            }
        default: return children;
    };
};
